import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'
import List from '../components/Common/List'

import * as s from '../pages/news.module.scss'

const DangerousJobs: React.FC = () => {
  return (
    <>
      <SEO
        title="The Most Dangerous Jobs in Construction"
        description="Employers need to know and recognize that working on a construction site always involves health risks. In this article, we'll look at three of the most dangerous jobs in construction."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          Top 3 Dangerous Jobs in Construction
        </Typography>
        <Typography variant="body1">
          The construction industry has undergone several improvements to reduce
          the rate of injuries to construction personnel. But despite the
          modifications with innovative technologies, this sector remains a
          dangerous universe that accounts for minor and fatal injuries. With
          that, construction workers remain at risk of developing occupational
          ailments.
        </Typography>
        <Typography variant="body1">
          Accidents are inevitable despite using the most suitable building
          equipment and having adequate knowledge, supervision and training.
          Although safety technologies have simplified complicated construction
          jobs, it remains a life-threatening profession.
        </Typography>
        <Typography variant="body1">
          In short, construction workers experience multiple hazards at work
          each day, from machinery accidents to falling debris and more. And
          below are the top three riskiest construction jobs associated with
          this domain.
        </Typography>
        <Typography variant="h2" color="blue">
          Roofing And High-Rise Work
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/top-3-dangerous-jobs-in-construction/image-1.jpeg"
            alt="Roofing And High-Rise Work"
            title="Roofing And High-Rise Work"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          <strong>Is construction a dangerous job?</strong> Unfortunately, the
          answer is a big yes. Around one in six deaths in construction
          locations happen because of roof installation. While some are
          professional roofers, others are cleaning and repairing experts. The
          prime causes of fatal injury or death are falling from the openings or
          roof edges via fragile roofs or roof lights.
        </Typography>
        <Typography variant="body1">
          Whether a new or an old roof, roofing is a complex job while building
          real estate. All roofing projects come with their sets of challenges.
          Especially when it comes to a high-rise building, installing the roof
          becomes <strong>dangerous construction</strong> work.
        </Typography>
        <Typography variant="body1">
          Note that falls from a high-rise building are one of the most
          significant causes of fatal injury or death. Falls from low heights
          may also leave workers with debilitating or permanent injuries,
          including:
        </Typography>
        <List className="pl-4">
          <li>Fractures</li>
          <li>Spinal cord injuries</li>
          <li>Brain damage and</li>
          <li>Concussion</li>
        </List>
        <Typography variant="body1">
          The higher the height of the building, the more the risk associated
          with severe injury from falling. The presence of the following items
          may also increase the chances of workplace injuries at a construction
          site:
        </Typography>
        <List className="pl-4">
          <li>Unprotected edges</li>
          <li>Fragile surfaces, holes, vents, or skylights</li>
          <li>
            Adverse weather conditions can be a reason (like wind or rain)
          </li>
          <li>
            Trip hazards (take the example of protrusions and roof components)
          </li>
          <li>Losing grip on sloping or steep-pitched roofs</li>
        </List>
        <Typography variant="body1">
          The building tasks <strong>dangerous construction jobs</strong> if
          they involve the following parameters:
        </Typography>
        <List className="pl-4">
          <li>Risks of a worker falling more than 2m</li>
          <li>Involvement with the asbestos</li>
        </List>
        <Typography variant="body1">
          Did you know roof edges & openings are also dangerous spots that may
          contribute to severe injuries? Falls from the roof edges happen on
          both domestic and commercial projects and new or refurbish jobs.
          Reports suggest that deaths occur even while working in small
          buildings. Sloping roofs are a big reason. They require scaffolding to
          protect workers from falling from the edge.
        </Typography>
        <Typography variant="body1">
          An edge protection system may be a great idea to prevent professionals
          from accidents in construction. For example, refurbishing or creating
          a new flat roof is one of the{' '}
          <strong>most dangerous construction jobs</strong>. To prevent workers
          from such accidents, it is imperative to secure the ladders.
        </Typography>
        <Typography variant="h2" color="blue">
          Demolition Work
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/top-3-dangerous-jobs-in-construction/image-2.jpeg"
            alt="Demolition Work"
            title="Demolition Work"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Wondering <strong>how dangerous is construction work</strong>? If yes,
          learn about the facts and facets of demolition work. Put simply;
          demolition work is the method of tearing down & removing old
          structures or buildings. The work is dangerous, so being cautious is
          quite important. Demolition personnel uses the tools to break down
          metal, concrete, and other similar materials.
        </Typography>
        <Typography variant="body1">
          So, <strong>is being a construction worker dangerous</strong>? If you
          have any doubts, learn more about the demolition work. In short,
          performing demolition jobs can be a physically demanding job. Workers
          should lift heavy objects & use power tools. They need balance and
          coordination while performing outdoor and indoor demolition work.
        </Typography>
        <Typography variant="body1">
          For workers who ask themselves, "
          <strong>is construction dangerous?</strong>" here are the risks
          involved:
        </Typography>
        <ul className={`${s.textLists}`}>
          <li>
            <Typography variant="body1">
              <strong>#1 Falls from Height or Uneven Surface</strong>
            </Typography>
            <Typography variant="body1">
              Falls are fatal, and the common hazards to demolition are falls.
              They may occur when professionals demolish any structure taller.
              Alternatively, they occur when they work on rough or uneven
              surface structures.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>
                #2 Sudden Collapse Of Structures Getting Demolished
              </strong>
            </Typography>
            <Typography variant="body1">
              Premature collapse may lead to fatal injuries on construction
              sites. Such accidents may occur if the demolition gets carried
              forward without safety precautions.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>#3 Falling Construction Debris</strong>
            </Typography>
            <Typography variant="body1">
              It's another common injury that might occur when the demolished
              pieces of the structure fall & hit the workers.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>#4 When Drainage Systems Silt Up</strong>
            </Typography>
            <Typography variant="body1">
              Although it's an unfamiliar hazard, it still occurs during
              demolition tasks. When the drainage systems get silted up by dust,
              workers may experience fatal injuries.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>#5 Fumes & Dust</strong>
            </Typography>
            <Typography variant="body1">
              <strong>Is construction the most dangerous industry</strong>?
              Well, learn about the demolition process. Dust gets created when
              professionals use power tools to demolish structures. It may have
              harmful impacts if and when inhaled. Materials such as asbestos
              may also release dangerous fumes into the air.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>#6 Spilt Fuel Oils &Their Dangers</strong>
            </Typography>
            <Typography variant="body1">
              The fuel oil gets used for powering the machinery used during the
              demolition task. When spilled, they might contribute to a
              dangerous impact (explosion or fire).
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>#7 Handling Things Manually</strong>
            </Typography>
            <Typography variant="body1">
              Manual handling may occur when professionals move or lift heavy
              objects like pieces of debris and bricks. They may contribute to
              severe injuries.
            </Typography>
          </li>
        </ul>
        <Typography variant="h2" color="blue">
          Electricity And Heating Grid
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/top-3-dangerous-jobs-in-construction/image-3.jpeg"
            alt="Electricity And Heating Grid"
            title="Electricity And Heating Grid"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          <strong>Why is construction dangerous</strong>? Since it involves
          tasks related to electricity & heating grid, the job is life-altering.
          Electrical hazards may contribute to severe jobsite accidents & fatal
          injuries. Reports suggest that it may lead to dangerous hazards.
        </Typography>
        <Typography variant="body1">
          <strong>Why is construction so dangerous</strong>? Here are a few
          dangers:
        </Typography>
        <List className="pl-4">
          <li>Underground and overhead powerlines</li>
          <li>Overloaded circuits and improper wiring</li>
          <li>Damaged electrical tools & equipment</li>
          <li>
            Temporary lighting and& exposed/open units for power distribution
          </li>
          <li>Operating the equipment in wet conditions</li>
          <li>Damaged or inadequate insulation</li>
          <li>Lack of accountability</li>
          <li>Untrained workers</li>
        </List>
        <Typography variant="body1">
          So, <strong>what is the most dangerous construction job</strong>? If
          anyone asks, you now know it's the tasks related to the electricity &
          heating grid. Since operating electricity is one of the{' '}
          <strong>most dangerous jobs in construction</strong>, risk awareness
          will promote safety.
        </Typography>
        <Typography variant="body1">
          If you want to mitigate risks of the construction site and
          <strong>construction dangerous jobs</strong>, SIGNAX services are here
          to help. SIGNAX is a set of digital products that can improve project
          management in the construction: give you full understanding what is
          happening on site, control the safety, prevent thefts and increase the
          opportunity of remote inspection using mobile devices in general.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default DangerousJobs
